// @import "../vendor/nice-select/css/nice-select.css";
// @import "../vendor/waves/waves.min.css";
// @import "../vendor/perfect-scrollbar/perfect-scrollbar.css";

* {
  outline: none;
  padding: 0;

  &::after {
    margin: 0;
    padding: 0;
  }

  &::before {
    margin: 0;
    padding: 0;
  }
}

body {
  &.fixed {
    overflow: hidden;
    position: fixed;
  }
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  // color       : $td;
  text-decoration: none;
  outline: none;

  &:hover,
  &:focus,
  &.active {
    text-decoration: none;
    outline: none;
    color: $primary;
  }
}

b,
strong,
.strong {
  font-weight: 500;
  color: $headings-color;
}

.h-0 {
  height: 0;
}

#main-wrapper {
  // opacity       : 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  // height     : 100vh;
  // padding-top: 75px;
  margin-top: 60px;

  &.show {
    opacity: 1;
  }
}

.content-body {
  margin-left: 6px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;

  @include respond("phone") {
    margin-left: 0px;
  }
}

// .container-fluid{
//     max-width: 95%;
// }

.profile_card {
    .media {
        border-bottom: 1px solid $border-color;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}

.card-profile__info {
    h5 {
        display: inline-block;
    }
}

.input-group-prepend {
    label {
        padding: 10px 15px;
        font-size: 1rem;
    }
}


.social-icons {
    margin-top: 20px;

    a {
        border-radius: 100px;
        padding: 7px 0px;
        margin-right: 15px;
        height: 42px;
        width: 42px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $white;
        background: $primary;
        text-align: center;
    }
}

.acc_balance {
    .btn-group {
        width: 100%;
    }
}

.linked_account {

    .media {
        // border       : 1px solid $border-color;
        align-items: center;
        // padding      : 20px;
        border-radius: 5px;

        &>span {
            background: $primary;
            color: $white;
            padding: 10px;
            border-radius: 50px;
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
        }

        .media-body {
            p {
                margin-bottom: 0px;
            }
        }
    }

    .edit-option {
        a {
            display: inline-block;
            padding: 5px;
            font-size: 18px;

            &:last-child {
                color: $danger;
            }
        }
    }

    .verify {

        .verified,
        .not-verify {
            display: flex;
            align-items: center;
            font-weight: 500;

            span {
                background: $success;
                color: $white;
                padding: 10px;
                border-radius: 50px;
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                margin-right: 15px;
                font-weight: bold;
            }
        }

        .not-verify {
            span {
                background: $danger;
            }
        }
    }
}

//
#deposits {

    .nav-tabs {
        border-color: $border-color;

        li {
            a {
                border-top: 0px;
                border-left: 0px;
                border-right: 0px;
                font-size: 16px;
                font-weight: 500;
                color: $body-color;
                border-color: $border-color;

                &.active {
                    background: $primary;
                    color: $white;
                    border-bottom: 1px solid $primary;
                }
            }
        }
    }


    .tab-content {
        .qrcode {
            text-align: center;
            margin-top: 50px;
            padding: 20px 0;
        }

        form {
            max-width: 750px;
            margin: 30px auto 50px;
        }

        ul {
            max-width: 750px;

            li {
                padding-left: 25px;
                position: relative;
                padding-bottom: 7px;

                i {
                    font-size: 16px;
                    margin-right: 7px;
                    position: absolute;
                    left: 0;
                }
            }
        }
    }
}

.important-info {
    ul {
        max-width: 850px;

        li {
            padding-left: 25px;
            position: relative;
            padding-bottom: 7px;

            i {
                font-size: 16px;
                margin-right: 7px;
                position: absolute;
                left: 0;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.link-disabled {
    pointer-events: none;
}

.verify-outlined {
    animation: verify-animation 0.8s ease-out 2s forwards 3;
    outline: none;
}

@keyframes verify-animation {
    0% {
        outline: 1px solid red;
        outline-offset: 2px;
    }

    100% {
        outline: 0px solid red;
        outline-offset: 30px;
    }
}
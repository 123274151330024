.modal {
    .modal-content {
        background-color: $dark;

        .modal-header>button {
            background-color: $dark;
            color: $white;
        }
    }
}

@keyframes stakeArrowMotion {
    0% {
        top: 0rem;
    }

    25% {
        top: 1rem;
    }

    50% {
        top: 2rem;
    }

    75% {
        top: 3rem;
    }

    100% {
        top: 4rem;
    }
}

.stake-line {
    margin-top: 0.5rem;
    height: 4.5rem;
    i {
        position: relative;
        animation: stakeArrowMotion 2s linear infinite;
    }
}

.farm-logo-td {
    position: sticky;
    left: 0px;
    backdrop-filter: blur(3px);
}
.demo_img {
  text-align: center;
  margin-bottom: 60px;

  .img-wrap {
    height: 180px;
    overflow: hidden;
    margin-bottom: 15px;
    background: $dark;
    padding: 10px;
    border-radius: 5px;
    position: relative;

    &.light {
      background: $white;
    }

    &:hover,
    &:focus {
      box-shadow: 0px 0px 10px #a1a0a799;
    }
  }

  img {
    border-radius: 5px;
  }
}

.intro-demo_img {
  // box-shadow: 0px 20px 25px rgba(22, 28, 45, 0.05);
  box-shadow: 0px 36px 48px rgba(93, 120, 255, 0.25);
  position: relative;
  overflow: hidden;

  // background: $primary;
  // padding   : 10px;
  img {
    border-radius: 5px;
  }

  .dark-img {
    position: absolute;
    // top  : 45px;
    // left : 50px;
    width: 90%;
    right: 0px;
    bottom: 0px;
  }
}

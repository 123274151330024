.intro {
  padding: 100px 0px;
  // border-bottom: 1px solid $border-color;

  .intro-content {
    h1 {
      line-height: 55px;
      margin-bottom: 30px;
      // strong {
      //     font-weight: 500;
      // }
    }

    p {
      font-size: 20px;
      margin-bottom: 50px;
    }
  }
}

.intro-form {
  position: relative;
  //   margin-bottom: 50px;
  max-width: 450px;
  margin: 0 auto 50px;

  form {
    display: flex;
  }

  button {
    position: relative;
    background: $primary;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 7px 20px;
    color: $white;
    font-size: 24px;
    overflow: hidden;
    width: 60px;
    min-width: 60px;
    border: 0px;
    margin-left: 15px;

    .first {
      position: absolute;
      right: 17px;
      top: 12px;
      z-index: 1;
      transition: all 0.2s ease-in-out;
    }

    .second {
      position: absolute;
      left: -22px;
      top: 12px;
      z-index: 1;
      transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
      outline: none;

      .first {
        right: -22px;
      }

      .second {
        left: 20px;
      }
    }
  }
}

.intro-btn {
  @include respond("tab-port") {
    margin-bottom: 50px;
  }

  .btn-primary {
    padding: 10px 30px;
    margin-right: 30px;
    font-weight: 500;
    font-size: 16px;
  }

  .btn-outline-primary {
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
  }
}

.intro-form-exchange {
  padding: 30px;
  box-shadow: 0px 20px 25px rgba(22, 28, 45, 0.05);
  border-radius: 5px;

  .nice-select {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .btn {
    padding: 10px 15px;
    font-weight: 500;
    position: relative;
    font-size: 16px;

    i {
      font-size: 22px;
      font-weight: bold;
      position: absolute;
      right: 15px;
    }
  }
}

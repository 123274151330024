.sponsor {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
}

.sponsor-logo {
    display: flex !important;
    // flex-wrap: wrap;
    justify-content: space-between;
    a {
        display: block;
        margin-bottom: 50px;
    }
}
.table-responsive-sm {
  min-width: 48rem;
}

.table {
  margin-bottom: 0px;
  font-weight: 400;

  tr {
    td {
      border: 0px;
      vertical-align: middle;
      padding: 15px;
    }
  }
}

#dashboard {
  font-size: 1rem;
  .table {
    tr {
      td,
      th {
        padding: 5px 7px;
      }
    }
  }
}

.table {
  tr {
    th {
      font-weight: 500;
      padding: 0px 15px;
      color: #eee;
      height: 40px;
      border-bottom: none;
    }
  }
}

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $body-bg;
  }

  tfoot {
    border-top: 2px solid $border-color;
    tr {
      th {
        padding-top: 10px;
      }
    }
  }
}

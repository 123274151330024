@import "./../abstract/variable";

.sidebar-right {
  display: block;
  right: -15.625rem;
  position: fixed;
  top: 0;
  width: 15.625rem;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 15px;
  //   height: calc(100% - 7.0625rem);
  margin-top: 5rem;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  padding-bottom: 1.875rem;
  box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1);

  .sidebar-right-trigger {
    position: absolute;
    z-index: 9;
    top: 4.75rem;
    right: 100%;
    background-color: $primary;
    color: $white;
    display: inline-block;
    height: 2.125rem;
    width: 2.125rem;
    text-align: center;
    font-size: 1.3125rem;
    line-height: 2.2rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &:hover {
      color: $white;
    }
  }

  &.show {
    right: 0;
    z-index: 999;
  }

  .admin-settings {
    p {
      margin-bottom: 0.3125rem;
    }

    //All color switcher scss goes here
    input[type="radio"] {
      display: none;

      + label {
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 3px 8px;
        transition: all 0.1s ease;
        border-radius: 100px;
      }

      &:checked {
        + label {
          position: relative;

          &::after {
            height: 12px;
            width: 12px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 100px;
          }
        }
      }
    }
  }

  #nav_header{
    @each $name, $color in $theme_primary_color {
      &_#{$name} {
        + label {
          background-color: $color;
        }
      }
    }

    &_color_1 {
      + label {
        border: 1px solid $border-color;
      }
    }
  }
  
  
  #header{
    @each $name, $color in $theme_backgrounds {
      &_#{$name} {
        + label {
          background-color: $color;
        }
      }
    }

    &_color_1 {
      + label {
        border: 1px solid $border-color;
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }
  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }
  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%);
  }
}

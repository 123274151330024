.get-touch {
  // border-bottom: 1px solid $border-color;
}

.get-touch-content {
  // text-align: center;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  background: $dark;
  border-radius: 5px;
  span {
    margin-right: 16px;
    i {
      color: $primary;
      font-size: 50px;
    }
  }
  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

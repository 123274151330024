.card {
  border: 0px solid $border-color;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  background: #131722;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    background: #131722;
    padding: 15px 20px 0;
    // margin      : 0px 35px;
    // @include respond("desktop") {
    //     padding: 10px;
    // }
    // @include custommq($min: 1200px, $max: 1350px) {
    //     padding: 10px 0px;
    // }

    &.token-search {
      @include respond("phone") {
        flex-direction: column;
        align-items: flex-start;

        .token-search {
          width: 100%;
        }
      }
    }
  }
  &-title {
    font-size: 16px;
    margin-bottom: 0px;
    // color: #ffffff;
    // font-weight: 500;
  }
  .card-body {
    padding: 15px 20px;
    background: #131722;
    border-radius: 15px;
    // @include respond("desktop") {
    //     padding: 10px;
    // }
    // @include custommq($min: 1200px, $max: 1350px) {
    //     padding: 10px 0px!important;
    // }
  }

  .account-overview-table {
    padding-top: 0;
    max-height: 500px;
    overflow-y: auto;
  }
}

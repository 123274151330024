body {
  margin: 0;
  font-family: "Poppins, sans-serif", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

:root {
  /* --bs-body-font-family: "Relative-Medium-Pro", sans-serif !important; */
  --bs-heading-color: #eee !important;
  --bs-body-font-size: 14px !important;
  --bs-body-line-height: normal !important;
}

.text-20 {
  font-size: 20px;
}

.text-18 {
  font-size: 18px;
}

.text-16 {
  font-size: 16px;
}

.text-14 {
  font-size: 14px;
}

.text-white-80 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

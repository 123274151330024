.header {
  padding: 10px 0px;
  background: $dark;
  border-bottom: 0px solid $border-color;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  // box-shadow: 0 0.05rem 0.01rem rgba(75,75,90,.075);
  &.fixed {
    padding: 10px 0px;
    box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
    border-color: transparent;
  }

  .navigation {
    // text-align: right;
    .navbar {
      background-color: transparent !important;
      padding: 0px;

      .collapse {
        justify-content: flex-end;
      }

      .navbar-nav {
        align-items: center;

        @include respond("tab-port") {
          margin-top: 15px;
        }

        & > .nav-item {
          display: inline-block;
          padding: 7px;

          @include respond("tab-port") {
            border-bottom: 1px solid $border-color;
            margin: 0 15px;
            padding: 7px 15px;
            width: 50%;
          }

          & > a {
            font-size: 16px;
            font-weight: 500;
            color: $body-color;
            opacity: 1;

            &.active {
              color: $primary;
            }

            @include respond("tab-port") {
              &::after {
                position: absolute;
                right: 15px;
                top: 25px;
              }
            }
          }
        }

        @media only screen and (min-width: 991px) {
          .dropdown {
            & > a {
              position: relative;
              transition: all 0.2s ease-in-out;
            }
          }

          .dropdown-menu {
            .dropdown-divider {
              border-top: 1px solid #a1a0a730;
            }

            a {
              display: inline-block;
              color: $body-color;
              font-size: 16px;
              font-weight: 500;
              margin: 8px 16px 8px 16px;

              &:focus,
              &.active {
                background: rgba(0, 0, 0, 0.1);
                color: $primary;
              }
            }
          }

          .nav-item.dropdown {
            &:hover {
              a {
                &::before {
                  opacity: 1;
                  bottom: -9px;
                }
              }

              .dropdown-menu {
                // display  : block;
                opacity: 1;
                visibility: visible;
                // transform: translate3d(-50%,0,0);
                margin-top: 0px;
              }
            }
          }
        }

        @include respond("tab-port") {
          .dropdown-menu {
            border: 0px;
            padding: 0px;
            background: rgba(0, 0, 0, 0.15);
            box-shadow: none;
            background: none;

            .dropdown-divider {
              border-top: 1px solid #a1a0a730;
            }

            a {
              padding: 15px;
              color: $body-color;

              & :last-child {
                border: 0px;
              }

              i,
              h6 {
                color: $primary;
              }

              p {
                color: $body-color;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.signin-btn {
  .btn {
    padding: 7px 15px !important;
    // color : $white ;
    min-width: 85px !important;
  }

  // @include respond("tab-port") {
  //     position: absolute;
  //     right   : 73px;
  // }
}


.testimonial{
    // background: #F1F4F8;
}

.testimonial-content{
    background: $dark;
    box-shadow: 0 1.5rem 4rem rgba(22,28,45,.05);
    border-radius: 5px;
    .owl-carousel{
        .owl-nav{
            // position: relative;
            .owl-prev{
                position: absolute;
                left:-29px;
                top: 50%;
                transform: translateY(-50%);
                width: 50px;
                height: 50px;
                min-width: auto;
                border-radius: 50px;
                background: $primary;
                color: $white;
                font-size: 18px;
                &:hover,&:focus{
                    outline: none;
                }
            }
            .owl-next{
                position: absolute;
                right:-29px;
                top: 50%;
                transform: translateY(-50%);
                width: 50px;
                height: 50px;
                min-width: auto;
                border-radius: 50px;
                background: $primary;
                color: $white;
                font-size: 18px;
                &:hover,&:focus{
                    outline: none;
                }
            }
        }
    }
}

.customer-img{
    img{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        position: relative;
        &::before{
            position: absolute;
            content: '';
        }
    }
}

.customer-review{
    padding: 30px;
    img{
        width: auto!important;
        margin-bottom: 30px;
        filter: brightness(100);
    }
    p{
        margin-bottom: 0px;
    }
}

.customer-info{
    margin-top: 20px;
    h6{
        font-size:18px;
    }
}
$theme_backgrounds: (
  "color_1": $color_background_1,
  "color_2": $color_background_2,
  "color_3": $color_background_3,
  "color_4": $color_background_4,
  "color_5": $color_background_5
);

$theme_primary_color: (
  "color_1": $color_primary_1,
  "color_2": $color_primary_2,
  "color_3": $color_primary_3,
  "color_4": $color_primary_4,
  "color_5": $color_primary_5
);

.price-grid {
  .card {
    border: 1px solid $border-color;
    // box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
    .card-header {
      padding: 15px 15px 0;
      border: 0px;
      .media {
        align-items: center;
      }
      .media-body {
        color: $headings-color;
      }
      i {
        margin-right: 10px;
        font-size: 30px;
      }
      p {
        font-size: 14px;
      }
    }
    .card-body {
      padding: 15px 15px 0px !important;
      h3 {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}

.coin-search {
  padding-bottom: 70px;
  .search-box {
    form {
      display: flex;
      .btn {
        margin-left: 15px;
        padding: 8px 30px;
      }
    }
  }
}

.sort-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;

  .search-timeline {
    a {
      color: $headings-color;
      font-size: 18px;
      display: inline-block;
      margin-right: 15px;
      font-weight: 500;
      &:hover,
      &:focus,
      &:active,
      &.active {
        color: $primary;
      }
    }
  }
  .coin-view {
    .nav {
      margin-bottom: 0px;
      .nav-item {
        &:last-child {
          .nav-link {
            margin-right: 0px;
          }
        }
        .nav-link {
          background: rgba(0, 0, 0, 0.15);
          color: $primary;
          margin-right: 10px;
          &:hover,
          &:focus,
          &:active,
          &.active {
            background: $primary;
            color: $white;
          }
        }
      }
    }
  }
}

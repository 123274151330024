.bottom {
  background: #131722;

  @include respond("phone") {
    text-align: center;
  }

  .bottom-logo {
    p {
      // color   : #b2b6c4;
      line-height: 30px;
      font-weight: 400;
    }
  }

  .bottom-widget {
    @include respond("tab-port") {
      margin-bottom: 30px;
    }

    .widget-title {
      margin-bottom: 16px;
    }

    ul {
      li {
        a {
          color: $body-color;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 8px;
          display: inline-block;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
  .bottom-footer {
    a {
      color: $body-color;

      &:hover {
        color: $primary;
      }
    }
  }
}

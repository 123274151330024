@import "./intro";
@import "./sponsor";
@import "./promo";
@import "./product";
@import "./brand";
@import "./features";
@import "./special";
@import "./testimonial";
@import "./support";
@import "./what-new";

.getstart-content {
  text-align: center;

  i {
    font-size: 80px;
    color: $primary;
  }

  h3 {
    margin-top: 15px;
    font-size: 20px;
  }

  @include respond("phone") {
    i {
      font-size: 60px;
    }

    h3 {
      font-size: 16px;
    }
  }
}

.promo {
  .promo-content {
    text-align: center;

    .promo-content-img {
      background: $primary;
      height: 130px;
      width: 130px;
      border-radius: 15px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 30px;

      img {
        max-width: 80px;
      }
    }

    h3 {
      margin-bottom: 15px;
    }
  }
}

.portfolio_list {
  .d-flex {
    h4 {
      font-size: 22px;

      @include respond("phone") {
        font-size: 16px;
      }
    }

    .port-icon {
      height: 65px;
      min-width: 65px;
      max-width: 65px;
      background: $dark;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      margin-right: 20px;
      color: $primary;
    }

    p {
      margin-bottom: 0px;

      a {
        color: $primary;
      }
    }
  }
}

.portfolio_img {
  .img-fluid {
    border-radius: 5px;
    box-shadow: 25px 60px 125px -25px rgba(0, 0, 0, 0.2), 16px 40px 75px -40px rgba(0, 0, 0, 0.2);

    @include respond("phone") {
      margin-top: 32px;
    }
  }

  @keyframes imageSpinning {
    0% {
      transform: rotate3d(0, 1, 0, 0deg);
    }

    20% {
      transform: rotate3d(0, 1, 0, 180deg);
      scale: 4;
      filter: none;
    }

    40% {
      transform: rotate3d(0, 1, 0, 360deg);
      scale: 8;
    }

    45% {
      transform: rotate3d(1, 0, 1, 20deg);
    }

    55% {
      transform: rotate3d(1, 0, 1, -20deg);
    }

    60% {
      transform: rotate3d(0, 1, 0, 360deg);
      scale: 8;
    }

    80% {
      transform: rotate3d(0, 1, 0, 540deg);
    }

    100% {
      transform: rotate3d(0, 1, 0, 720deg);
    }
  }

  .image-spin {
    display: inline-block;
    position: absolute;
    width: 1rem;
    margin-left: 0.2rem;
    animation: imageSpinning 5s linear 0s infinite alternate;
  }
}

// Trade app
.trade-app-content {
  border: 0px;
  background: $dark;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);

  .card-body {
    padding: 20px 30px;

    span {
      font-size: 48px;
      margin-bottom: 15px;
      display: inline-block;
      color: $primary;
    }

    h4 {
      font-size: 20px;
      margin-bottom: 15px;
    }

    p {
      min-height: 115px;

      @include respond("tab-land") {
        min-height: 165px;
      }

      @include respond("tab-port") {
        min-height: auto;
      }
    }

    a {
      color: $primary;
      display: flex;
      font-weight: 500;
      align-items: center;

      i {
        margin-left: 15px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

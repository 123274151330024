.appss-content {
  @include respond("tab-port") {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  h2 {
    margin-bottom: 30px;
    font-weight: 500;
  }
  ul {
    li {
      padding: 10px 0;
      font-size: 17px;
      i {
        font-weight: bold;
        font-size: 18px;
        color: $primary;
        margin-right: 15px;
      }
    }
  }
  .btn {
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    margin-right: 15px;
  }
}
